const isMenuOpen = () => {
	return document.querySelector('.ddc-document')?.classList?.contains('active');
};

const waitForElement = selector => {
	return new Promise(resolve => {
		const checkElement = () => {
			const element = document.querySelector(selector);
			if (element) {
				resolve(element);
			} else {
				window.requestAnimationFrame(checkElement);
			}
		};
		checkElement();
	});
};


/**
 *
 * @param {function} callback
 * @returns
 */
export const onLdClientReady = async callback => {
	if (!window.ldClient) {
		document.addEventListener('LDClientReady', callback);
	} else {
		await window.ldClient.waitUntilReady();
		callback();
	}
};

/**
 *
 * @param {boolean} eventResolved
 * @param {string} eventName
 * @returns
 */
export const waitForEvent = (eventResolved, eventName) => {
	return new Promise(resolve => {
		if (eventResolved) {
			resolve(eventResolved);
		} else {
			window.addEventListener(
				eventName,
				data => {
					resolve(data);
				},
				{ once: true }
			);
		}
	});
};

export const waitForMenu = () => {
	const menuOpenEventName = 'mobile-navbar/open';
	const targetElement = '[data-location~="primary-menu"]';

	return new Promise(resolve => {
		if (isMenuOpen) {
			waitForElement(targetElement)
				.then(() => resolve(true))
				.catch(() => resolve(false));
		} else {
			const handleMenuOpenEvent = async () => {
				await waitForElement(targetElement)
					.then(() => {
						resolve(true);
						window.DDC?.pubsub?.unsubscribe(menuOpenEventName);
					})
					.catch(() => resolve(false));
			};

			window.DDC?.pubsub?.subscribe(menuOpenEventName, handleMenuOpenEvent);
		}
	});
};

export const isDocumentReady = () => {
	return new Promise(resolve => {
		if (document.readyState !== 'loading') {
			resolve();
		} else {
			document.addEventListener('DOMContentLoaded', resolve, { once: true });
		}
	});
};

/**
 *
 * @returns {Promise<any>}
 */
export const isWIAPILoaded = async () => {
	return waitForEvent(
		window.DDC?.PrivateAPI?.integrationsLoaded,
		'WIAPILoaded'
	);
};

export const waitForObject = async (objectName, interval = 50, timeout = 5000) => {
	return new Promise((resolve, reject) => {
		const checkObject = setInterval(() => {
			if (window[objectName]) {
				clearInterval(checkObject);
				// eslint-disable-next-line no-use-before-define
				clearTimeout(timeoutHandler);
				resolve(true);
			}
		}, interval);

		const timeoutHandler = setTimeout(() => {
			clearInterval(checkObject);
			reject(new Error(`Timed out after ${timeout}ms waiting for ${objectName} to become available`));
		}, timeout);
	});
}
