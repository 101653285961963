import { trackAPIMethods } from '../../../tracking';
import { getVehicles } from '../get-vehicles';
import '../../../../types/init.d'

/**
 * Gets the oemSourcedMerchandising attribute for a vehicle by it's vin number.
 * 
 * @param { Init } init 
 * @param { string } vin 
 * @returns { Promise<string|undefined> }
 */
export const getOemSourcedMerchandisingStatusByVin = async (init, vin) => {

    // Get all vehicles
    const vehicles = await getVehicles('oemSourcedMerchandisingStatus')

    // Filter down to just the selected vehicle by vin
    const vehicleData = vehicles.find(vehicle => vehicle.vin === vin) || {}

    // if Intigration information is available, log the action
    if (init) {
        trackAPIMethods(init, {
            methodType: 'OEM Source Merchandising Status',
            status: 'Success'
        });
    }

    // return the oemSourcedMerchandisingStatus value
    return vehicleData.oemSourcedMerchandisingStatus;
}