import { trackAPIMethods } from '../tracking';
import { getCookie } from './cookies';

let currentPageData;

const hasDataLayer = () => {
	return (
		Object.keys(window.DDC.dataLayer).length &&
		Object.keys(window.DDC.siteSettings).length
	);
};

export const getPageMetaData = async () => {
	if (!hasDataLayer()) {
		throw new Error(
			'DDC DataLayer is not defined. Aborting Web Integration API.'
		);
	}

	if (!currentPageData) {
		const { pageInfo, attributes } = window.DDC.dataLayer.page;
		const { design, franchises } = window.DDC.dataLayer.site;
		const { siteSettings } = window.DDC;
		const searchPage = pageInfo.isVlp;
		const detailPage = pageInfo.isVdp;
		const indexPage = pageInfo.isHomepage;

		currentPageData = {
			accountId: siteSettings.accountId || '',
			siteId: siteSettings.siteId || '',
			defaultDomain: siteSettings.defaultDomain || '',
			indexPage,
			searchPage,
			detailPage,
			pageName: pageInfo.pageName || '',
			layoutType: attributes.layoutType || '',
			locale: attributes.locale || '',
			design,
			franchises,
			ga_clientId: getCookie('_ga') || '',
			ga_visitorId: getCookie('_gid') || ''
		};
	}

	return currentPageData;
};

export const getPageData = async (init, tracking = true) => {
	// Disable tracking for the autofi integration as it's
	// creating billions of unnecessary events.
	// TODO Remove this once autofi has fixed the issue.
	const doTracking = init.integrationId === 'autofi' ? false : tracking;

	const pageData = await getPageMetaData();

	if (doTracking) {
		trackAPIMethods(init, {
			methodType: 'getPageData',
			status: typeof pageData === 'object' ? 'Success' : 'Failed'
		});
	}

	return pageData;
};
