import { EventEmitter } from '../../event-emitter';
import { getVehicles } from '../../../utils/get-vehicles';
import { getPageMetaData } from '../../../utils/get-page-meta-data';
import { handlePaymentVisibility } from '../../../utils/payments';
import { hasInventoryData, hasDataBusInventory } from '../../../utils/helpers';

export class VehicleShownEventEmitter extends EventEmitter {
	constructor() {
		super('vehicle-shown-v1');
	}

	async invoke(vehicleData) {
		const pageMetaData = await getPageMetaData();
		const vehicles = await getVehicles();

		const fireVehicleEvents = async vehicle => {
			if (vehicle) {
				const foundVehicle = vehicles.filter(entry => {
					return entry.uuid === vehicle.uuid;
				});

				if (foundVehicle.length > 0) {
					const targetVehicle = foundVehicle[0];
					this.fireEvent({ payload: targetVehicle });
					handlePaymentVisibility();
				}
			} else {
				vehicles.forEach(entry => {
					this.fireEvent({ payload: entry });
					handlePaymentVisibility();
				});
			}
		};

		// We will likely want to open this up to all pages at some point,
		// but need to ensure it doesn't just constantly check for inventory on
		// all pages of all sites first.
		if (pageMetaData.searchPage || pageMetaData.detailPage) {
			// This checks if data is available in either the data layer or inventory data bus.
			// At this time, only one or the other will be populated, never both.
			await hasInventoryData();

			if ((hasDataBusInventory() && vehicleData) || !hasDataBusInventory()) {
				await fireVehicleEvents(vehicleData);
			}
		}
	}
}
