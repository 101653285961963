import { BadgeLocation } from './vehicle-badge';
import { FacetLocation } from './search-facet';
import { HeaderToolbarLocation } from './header-toolbar';
import { ListingLocation } from './listing-location';
import { MediaLocation } from './vehicle-media';
import { PageLocation } from './page-location';
import { VehicleLocation } from './vehicle-location';

import {
	UPDATE,
	MODIFY_CTA,
	RESTRICTIVELY_MODIFY_CTA,
	MODIFY_LINKS
} from '../constants';

const listingsPlaceholderPrefix = 'listings-placeholder';
const listingsAdPrefix = 'listings-ad';

// Note: Please maintain an alphabetized list for the following target locations
// We display them to the end user in the error log messages as a
// list of valid target locations. If, the integration is trying
// to modify an unsupported location.
export const insertLocations = Object.freeze({
	content: new PageLocation('content'),
	'digital-retailing': new VehicleLocation('digital-retailing'),
	'facet-browse': new PageLocation('facet-browse'),
	'header-toolbar': new HeaderToolbarLocation('header-toolbar'),
	heading: new PageLocation('heading'),
	'listing-filters': new ListingLocation('listing-filters'),
	'listings-placeholder-1': new ListingLocation(
		`${listingsPlaceholderPrefix}-1`
	),
	'listings-placeholder-2': new ListingLocation(
		`${listingsPlaceholderPrefix}-2`
	),
	'listings-placeholder-3': new ListingLocation(
		`${listingsPlaceholderPrefix}-3`
	),
	'listings-placeholder-4': new ListingLocation(
		`${listingsPlaceholderPrefix}-4`
	),
	'listings-placeholder-5': new ListingLocation(
		`${listingsPlaceholderPrefix}-5`
	),
	'listings-ad-1': new ListingLocation(`${listingsAdPrefix}-1`),
	'listings-ad-2': new ListingLocation(`${listingsAdPrefix}-2`),
	'listings-ad-3': new ListingLocation(`${listingsAdPrefix}-3`),
	'mobile-vdp-footer': new VehicleLocation('mobile-vdp-footer', {
		elementType: 'li',
		clearContent: true
	}),
	'primary-menu-top': new PageLocation('primary-menu-top', {
		classes: 'panel panel-default',
		elementType: 'li',
		singleLocation: true,
		placement: 'first'
	}),
	'primary-menu-bottom': new PageLocation('primary-menu-bottom', {
		classes: 'panel panel-default',
		elementType: 'li',
		singleLocation: true
	}),
	'page-footer': new PageLocation('page-footer'),
	'primary-banner': new PageLocation('primary-banner', {
		classes: 'mt-3 px-4'
	}),
	'privacy-form': new PageLocation('privacy-form', {
		clearContent: true,
		singleLocation: true
	}),
	'search-facet-pricing': new FacetLocation('search-facet-pricing'),
	'secondary-content': new PageLocation('secondary-content'),
	'site-text-search': new PageLocation('site-text-search'),
	'vehicle-badge': new BadgeLocation('vehicle-badge'),
	'vehicle-badge-custom': new BadgeLocation('vehicle-badge-custom'),
	'vehicle-build-offer': new VehicleLocation('vehicle-build-offer', { classes: 'mb-3' }),
	'vehicle-ctas': new VehicleLocation('vehicle-ctas', { classes: 'mb-3' }),
	'vehicle-ctas-bottom': new VehicleLocation('vehicle-ctas-bottom', {
		classes: 'mb-3'
	}),
	'vehicle-ctas-custom': new VehicleLocation('vehicle-ctas-custom', {
		classes: 'mb-3'
	}),
	'vehicle-ctas-top': new VehicleLocation('vehicle-ctas-top', {
		classes: 'mb-3'
	}),
	'vehicle-media': new MediaLocation('vehicle-media'),
	'vehicle-media-container': new VehicleLocation('vehicle-media-container'),
	'vehicle-media-custom': new MediaLocation('vehicle-media-custom'),
	'vehicle-payments': new VehicleLocation('vehicle-payments', {
		classes: 'mb-3',
		singleLocation: true
	}),
	'vehicle-payments-primary': new VehicleLocation('vehicle-payments-primary', {
		classes: 'mb-3',
		singleLocation: true
	}),
	'vehicle-payments-custom': new VehicleLocation('vehicle-payments-custom', {
		classes: 'mb-3',
		singleLocation: true
	}),
	'vehicle-pricing': new VehicleLocation('vehicle-pricing', {
		classes: 'mb-3'
	}),
	'vehicle-pricing-custom': new VehicleLocation('vehicle-pricing-custom', {
		classes: 'mb-3'
	})
});

const allowAllUpdatesToButtonIntents = [
	'chat',
	'check-availability',
	'delivery',
	'digital-retailing',
	'eprice',
	'location-finder',
	'payment-calculator',
	'pre-approval',
	'request-a-quote',
	'reserve-it-now',
	'send-to-phone',
	'social',
	'test-drive',
	'text-us',
	'value-a-trade',
	'window-sticker'
];

// Only the the button actions can be
// modified for these buttons
export const allowSelectedUpdatesToButtonIntents = ['ask', 'drive'];
export const allowSelectedUpdatesToButtonClasses = [];

const allowedLinkUpdateLocations = [
	'x-time',
	'schedule-service',
	'quote-build',
	'value-a-trade',
	'payment-search',
	'pre-approval'
];

const allowAllUpdatesToButtonTypes = {};
const allowSelectedUpdatesToButtonTypes = {};

allowAllUpdatesToButtonIntents.forEach(value => {
	const buttonIdentifier = `vehicle-${value}-button`;
	allowAllUpdatesToButtonTypes[buttonIdentifier] = new VehicleLocation(
		buttonIdentifier
	);
});

allowSelectedUpdatesToButtonIntents.forEach(value => {
	const buttonIdentifier = `vehicle-${value}-button`;
	allowSelectedUpdatesToButtonClasses.push(buttonIdentifier);
	allowSelectedUpdatesToButtonTypes[buttonIdentifier] = new VehicleLocation(
		buttonIdentifier
	);
});

export const updateLocations = Object.freeze({
	...allowAllUpdatesToButtonTypes,
	...allowSelectedUpdatesToButtonTypes
});

const insertLocationNames = Object.keys(insertLocations);
const allowAllUpdateLocationNames = Object.keys(allowAllUpdatesToButtonTypes);
const allowSelectedUpdateLocationNames = Object.keys(
	allowSelectedUpdatesToButtonTypes
);

export const validateLocationName = (methodType, locationName) => {
	let locationNames = insertLocationNames;
	if (methodType === UPDATE) {
		locationNames = allowAllUpdateLocationNames;
	}
	if (methodType === MODIFY_CTA) {
		locationNames = [...allowAllUpdateLocationNames, ...insertLocationNames];
	}
	if (methodType === RESTRICTIVELY_MODIFY_CTA) {
		locationNames = allowSelectedUpdateLocationNames;
	}
	if (methodType === MODIFY_LINKS) {
		locationNames = allowedLinkUpdateLocations;
	}
	if (!locationNames.includes(locationName)) {
		throw new Error(
			`Invalid location: ${locationName}. Valid options ` +
				`are: ${locationNames.join(', ')}.`
		);
	}
};
