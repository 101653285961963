import { applyButtonOverrides } from '../create/button-markup';
import { activateModules } from '../utils/helpers';
import {
	validateIfLocationIsModifiable,
	validateInitIsAnObject,
	validateTheModifierObjectForLocation
} from '../utils/validator';
import { modifyLocations } from './locations';
import { setPageLevelModification } from './osirisContentModifications';

export class Modifier {
	constructor(init, location, modifierObject) {
		validateInitIsAnObject(init, 'init');
		validateIfLocationIsModifiable(location);
		validateTheModifierObjectForLocation(location, modifierObject);

		// Update the private api and trigger custom event with page level changes
		// So, that individual osiris widgets can handle these changes
		setPageLevelModification(init.integrationId, location, modifierObject);

		const { schema, callback } = modifierObject;

		this.init = init;
		this.location = location;
		this.locationHandler = modifyLocations[this.location];
		this.schema = schema;
		this.integrationCall = callback;

		this.apply = this.apply.bind(this);
	}

	async apply() {
		await this.locationHandler.isReady();
		const locationElems = await this.locationHandler.getElements();

		if (!locationElems) {
			return;
		}

		locationElems.forEach(locationElem => {
			switch (this.location) {
				case 'schedule-service': {
					const newButtonMarkup = applyButtonOverrides(
						this,
						locationElem,
						this.schema,
						false /* Not a vehicle cta */
					);
					activateModules(newButtonMarkup);
					break;
				}

				default:
					break;
			}
		});
	}
}
