import { Location } from '../../location';
import { getPageMetaData } from '../../utils/get-page-meta-data';
import { hasFacetData } from '../../utils/helpers';

export class FacetLocation extends Location {
	constructor(name) {
		super(name || 'search-facet');
	}

	isReady() {
		return hasFacetData();
	}

	async getConfig() {
		return {
			classes: 'facet m-0 mt-3',
			elementType: 'div',
			responsiveSrpClasses: 'm-0 mb-3 px-3',
			singleLocation: true
		};
	}

	getMeta() {
		return getPageMetaData();
	}
}
