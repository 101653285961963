import {
    convertStringToObject,
    convertStringToArray,
    convertFormattedPriceToInt
} from '../data-formatters';
import { getPageMetaData } from '../get-page-meta-data';
import {
    hasInventoryData,
    hasLegacyInventory,
    hasDataBusInventory,
    hasLegacyGridViewInventory,
    query,
    queryAll
} from '../helpers';
import { log } from '../../log';
import { trackAPIMethods } from '../../tracking';

// Set up pricing fields.
const allowedPriceFields = [
    'askingPrice',
    'internetPrice',
    'msrp',
    'oemPriceMapHighMSRP',
    'retailValue',
    'salePrice'
];

const isHiddenElement = el => {
    return el.offsetParent === null;
};

const getInventoryType = (type, newOrUsed) => {
    if (newOrUsed) {
        return newOrUsed;
    }

    let inventoryType = type.toLowerCase();

    if (
        inventoryType === 'certified' ||
        inventoryType.includes('pre') ||
        inventoryType.includes("d'occasion") ||
        inventoryType === 'usado'
    ) {
        inventoryType = 'used';
    } else if (inventoryType.includes('neuf') || inventoryType === 'nuevo') {
        inventoryType = 'new';
    }

    return inventoryType;
};

const getIsCertified = (type, certified) => {
    return (
        type === 'certified' || certified === 'true' || certified === true || false
    );
};

const addDynamicFields = (vehicleObject, data) => {
    const vehicle = vehicleObject;

    // Use a default of 0 if no value is set.
    vehicle.highestPrice = vehicle.highestPrice || 0;
    vehicle.startingPrice = vehicle.startingPrice || 0;
    vehicle.finalPrice = vehicle.finalPrice || 0;

    allowedPriceFields.forEach(priceField => {
        const field = priceField.toLowerCase();
        const value = data[field] || data[priceField] || 0;
        const formattedPrice = convertFormattedPriceToInt(value);
        vehicle[priceField] = formattedPrice;
        if (formattedPrice > vehicle.highestPrice) {
            vehicle.highestPrice = formattedPrice;
        }
    });

    if (vehicle.startingPrice > vehicle.highestPrice) {
        vehicle.highestPrice = vehicle.startingPrice;
    }

    if (vehicle.finalPrice > vehicle.highestPrice) {
        vehicle.highestPrice = vehicle.finalPrice;
    }

    if (vehicle.finalPrice === 0) {
        if (vehicle.startingPrice > 0) {
            vehicle.finalPrice = vehicle.startingPrice;
        } else if (vehicle.highestPrice > 0) {
            vehicle.finalPrice = vehicle.highestPrice;
        }
    }

    // If there's no starting price but there is a final price, set the starting price as the same value so it will be defined.
    if (!vehicle.startingPrice && vehicle.finalPrice) {
        vehicle.startingPrice = vehicle.finalPrice;
    }

    return vehicle;
};

const formatDataBusInventory = async (keys) => {
    const dataLayerVehicles = window.DDC.dataLayer.vehicles || [];
    const dataBusVehicles = window.DDC?.InvData?.inventory
        ? window.DDC.InvData.inventory.inventory
        : [];

    const whitelistedFields = [
        'accountId',
        'address',
        'autodataCaId',
        'bodyStyle',
        'certified',
        'chromeId',
        'classification',
        'dealerCodes',
        'doors',
        'driveLine',
        'engine',
        'engineSize',
        'exteriorColor',
        'deliveryDateRange',
        'fuelType',
        'images',
        'interiorColor',
        'inventoryDate',
        'inventoryType',
        'make',
        'model',
        'modelCode',
        'odometer',
        'optionCodes',
        'packageCode',
        'stockNumber',
        'transmission',
        'trim',
        'uuid',
        'vin',
        ...keys
    ];

    const renamedAttributes = {
        cityFuelEfficiency: 'cityFuelEconomy',
        highwayFuelEfficiency: 'highwayFuelEconomy'
    };

    return dataLayerVehicles.map(vehicle => {
        // Set up a few default fields
        const vehicleObject = {
            highestPrice: 0,
            startingPrice: 0,
            year: vehicle.modelYear
        };

        // Add whitelisted fields if available on the vehicle object.
        whitelistedFields.forEach(field => {
            if (vehicle[field] !== undefined && !vehicleObject[field]) {
                const invType = vehicle.inventoryType;
                if (field === 'certified') {
                    vehicleObject[field] = getIsCertified(invType, vehicle[field]);
                } else if (field === 'inventoryType') {
                    vehicleObject[field] = getInventoryType(invType, vehicle.newOrUsed);
                } else {
                    vehicleObject[field] = vehicle[field];
                }
            }
        });

        // Status
        vehicleObject.status = vehicle.status
            ? vehicle.status.replace(/ /g, '_').toLowerCase()
            : '';

        // Add fields that we want to rename as we place onto the object.
        Object.entries(renamedAttributes).forEach(mapping => {
            if (vehicle[mapping[0]]) {
                vehicleObject[mapping[1]] = vehicle[mapping[0]];
            }
        });

        if (vehicle.link !== undefined) {
            vehicleObject.link = `${window.location.origin}${vehicle.link}`;
        }

        // Normalize Option Codes
        if (
            vehicleObject.optionCodes === undefined ||
            (vehicleObject.optionCodes.length === 1 &&
                vehicleObject.optionCodes[0] === '')
        ) {
            vehicleObject.optionCodes = [];
        }

        // Simplify image array to a single image URL to save browser memory.
        if (
            vehicleObject.images &&
            vehicleObject.images.length > 0 &&
            vehicleObject.images[0].uri
        ) {
            vehicleObject.images = [vehicleObject.images[0].uri];
        }

        const [dbVehicle] = dataBusVehicles.filter(item => {
            if (item.uuid === vehicleObject.uuid) {
                return item;
            }
            return false;
        });

        const pricing = dbVehicle?.pricing?.dPrice || dbVehicle?.pricing?.dprice;

        let startingPriceFound = false;

        if (dbVehicle && pricing) {
            Object.entries(pricing).forEach(price => {
                if (price.length < 2) {
                    return;
                }
                const priceObject = price[1];
                const priceField = priceObject.typeClass;
                const allowedPriceField =
                    priceField &&
                    (allowedPriceFields.includes(priceField) || priceObject.isFinalPrice);
                if (allowedPriceField) {
                    const value =
                        priceObject.value === 'PLEASE_CALL'
                            ? 0
                            : convertFormattedPriceToInt(priceObject.value);

                    // Set this specific price field on the object.
                    if (value > 0) {
                        // Set the most expensive price as `highestPrice`
                        if (value > vehicleObject.highestPrice) {
                            vehicleObject.highestPrice = value;
                        }

                        if (allowedPriceFields.includes(priceField)) {
                            vehicleObject[priceField] = value;
                        }

                        if (priceObject.isFinalPrice) {
                            if (
                                vehicleObject.highestPrice > 0 &&
                                vehicleObject.highestPrice / 3 < value
                            ) {
                                vehicleObject.finalPrice = value;
                            }
                        }

                        if (startingPriceFound === false) {
                            vehicleObject.startingPrice = value;
                            startingPriceFound = true;
                        }
                    }
                }
            });
            if (!vehicleObject.finalPrice && vehicleObject.startingPrice) {
                vehicleObject.finalPrice = vehicleObject.startingPrice;
            }
        }

        return addDynamicFields(vehicleObject, vehicle);
    });
};

const formatLegacyDataLayerInventory = async (keys) => {
    const pageMetaData = await getPageMetaData();

    if (!window.DDC.dataLayer) {
        return [];
    }

    const dlVehicles = window.DDC.dataLayer.vehicles || [];

    const vehicles =
        pageMetaData.detailPage && dlVehicles.length > 0
            ? [dlVehicles[0]] // Single VDP vehicle
            : queryAll('[data-uuid]'); // List of legacy SRP vehicles

    // Set up ridiculously large selector to find the correct final price.
    const allowedPriceClasses = [
        ...allowedPriceFields,
        'oemPriceMapHighMSRP',
        'final-price',
        'stackedFinal'
    ];

    let priceClasses = [];
    allowedPriceClasses.forEach(priceClass => {
        priceClasses.push(`.${priceClass} .value`);
        priceClasses.push(`.${priceClass} .price`);
        priceClasses.push(`.${priceClass} .price-value`);
    });
    priceClasses.push('.final-price .price');
    priceClasses = priceClasses.join(', ');

    return vehicles.map(vehicle => {
        const images = [];

        let finalPrice = 0;
        let link;
        let prices;
        let startingPrice = 0;
        let { uuid } = vehicle;
        let vehicleData;

        if (pageMetaData.detailPage) {
            vehicleData = query('.ws-detailed-pricing, .inventory-detail-pricing');
            link = `${window.location.origin}${window.location.pathname}`;
        } else if (pageMetaData.searchPage && query('.hproduct', vehicle)) {
            vehicleData = query('.hproduct', vehicle);

            uuid = vehicle.getAttribute('data-uuid');

            const url = query('.url', vehicleData);
            if (url && url.href) {
                link = url.href;
            }
        }

        if (vehicleData && typeof vehicleData.dealerCodes === 'string') {
            vehicleData.dealerCodes = JSON.parse(vehicleData.dealerCodes);
        }

        let dlVehicle;
        if (dlVehicles.length > 0) {
            [dlVehicle] = dlVehicles.filter(el => {
                if (el.uuid === uuid) {
                    return el;
                }
                return false;
            });

            if (!dlVehicle) {
                log(
                    'INFO',
                    `No data found in data layer for vehicle UUID ${uuid}. Skipping.`
                );
                return false;
            }
        } else if (vehicleData) {
            dlVehicle = vehicleData;
        }

        if (dlVehicle.address && dlVehicle.accountName) {
            dlVehicle.address.accountName = dlVehicle.accountName;
        }

        // Simplify image data to just the first image URL
        if (dlVehicle.images && dlVehicle.images.length > 0) {
            images.push(dlVehicle.images[0].uri);
        }

        if (pageMetaData.detailPage) {
            prices = queryAll(priceClasses);
        } else if (pageMetaData.searchPage && vehicleData) {
            prices = queryAll(priceClasses, vehicleData);
        }

        let startingPriceFound = false;
        if (prices.length > 0) {
            prices.forEach(price => {
                const parentClasses =
                    price.parentElement && price.parentElement.classList.length > 0
                        ? Array.from(price.parentElement.classList)
                        : [];

                parentClasses.forEach(parentClass => {
                    if (allowedPriceClasses.includes(parentClass)) {
                        const newPrice = convertFormattedPriceToInt(price.innerText);

                        if (newPrice && !startingPriceFound) {
                            startingPrice = newPrice;
                            startingPriceFound = true;
                        }

                        /*
                            I don't feel good about `isProbablyNotAFeeOrDiscount`, but I can't think of a better way to solve for this issue
                            without a great deal of work and probably introducing new bugs to various parts of our system.

                            This hack is necessary to support bizarre pricing usage on sites like these:

                            https://www.ramseychryslerjeepdodge.com/
                            https://www.echelonfordinc.com/used-inventory/index.htm
                            https://www.southwestmotors.com/

                            In some cases, a pricing field like `wholesalePrice` or `retailValue` is used as a Dealer Fee or Discount.
                            Sometimes, these discounts are shown last in the pricing stack and are therefore tagged with a final-price
                            class because the last 'price' shown is assumed to be 'final-price'.

                            So here, we horribly test to see if the price we are considering using as final-price is not less than
                            one third of the value of the last selected price. This helps to avoid situations where a vehicle listed at
                            $30,000 with a 'final-price' Dealer Fee of $499 is accidentally put in the API with a final price of $499.

                            I'm sorry, and please submit better ideas if you have them. :)
                        */

                        const isProbablyNotAFeeOrDiscount = finalPrice / 3 < newPrice;

                        if (
                            finalPrice === 0 ||
                            (finalPrice > 0 && isProbablyNotAFeeOrDiscount)
                        ) {
                            finalPrice = newPrice;
                        }
                    }
                });
            });
        }

        const certified = getIsCertified(
            dlVehicle.inventoryType,
            dlVehicle.certified
        );
        const inventoryType = getInventoryType(
            dlVehicle.inventoryType,
            dlVehicle.newOrUsed
        );

        const formattedVehicle = {
            accountId: dlVehicle.accountId || '',
            address: dlVehicle.address || {},
            autodataCaId: dlVehicle.autodataCaId || '',
            bodyStyle: dlVehicle.bodyStyle || '',
            certified,
            chromeId: dlVehicle.chromeId || '',
            cityFuelEconomy: parseInt(dlVehicle.cityFuelEfficiency, 10) || 0,
            classification: dlVehicle.classification || '',
            dealerCodes:
                typeof dlVehicle.dealerCodes === 'string'
                    ? JSON.parse(dlVehicle.dealerCodes)
                    : dlVehicle.dealerCodes || {},
            doors: dlVehicle.doors || '',
            driveLine: dlVehicle.driveLine || '',
            engine: dlVehicle.engine || '',
            engineSize: dlVehicle.engineSize || '',
            deliveryDateRange: dlVehicle.deliveryDateRange || '',
            exteriorColor: dlVehicle.exteriorColor || '',
            finalPrice,
            fuelType: dlVehicle.fuelType || '',
            highwayFuelEconomy: parseInt(dlVehicle.highwayFuelEfficiency, 10) || 0,
            images,
            interiorColor: dlVehicle.interiorColor || '',
            inventoryDate: dlVehicle.inventoryDate || '',
            inventoryType,
            link,
            make: dlVehicle.make || '',
            model: dlVehicle.model || '',
            modelCode: dlVehicle.modelCode || '',
            odometer: parseInt(dlVehicle.odometer, 10) || 0,
            oemSourcedMerchandisingStatus: vehicle.oemSourcedMerchandisingStatus || '',
            optionCodes:
                dlVehicle.optionCodesOther && dlVehicle.optionCodesOther.length
                    ? dlVehicle.optionCodesOther
                    : dlVehicle.optionCodes || [],
            packageCode: dlVehicle.packageCode || '',
            packages: dlVehicle.packages ? dlVehicle.packages : '',
            startingPrice,
            status: dlVehicle.status
                ? dlVehicle.status.replace(/ /g, '_').toLowerCase()
                : '',
            stockNumber: dlVehicle.stockNumber || '',
            transmission: dlVehicle.transmission || '',
            trim: dlVehicle.trim || '',
            uuid: dlVehicle.uuid || '',
            vin: dlVehicle.vin || '',
            year: parseInt(dlVehicle.modelYear, 10) || 0
        };

        const additionalFieldsVehicle = keys.reduce((resVehicle, key) => {
            return {
                ...resVehicle,
                [key]: dlVehicle[key] || ''
            }
        }, formattedVehicle)

        return addDynamicFields(additionalFieldsVehicle, dlVehicle);
    });
};

const formatLegacyGridViewInventory = async (keys) => {
    const pageMetaData = await getPageMetaData();

    if (
        !pageMetaData.searchPage ||
        queryAll('.inventory-listing-grid').length === 0
    ) {
        log(
            'INFO',
            'Not a grid view inventory page. Skipping further inventory processing.'
        );
        return false;
    }

    const vehicles = queryAll('[data-uuid]');

    return vehicles.map(vehicle => {
        const vehicleData = vehicle.dataset;
        const images = [];
        const url = query('.url', vehicle);
        const link = url && url.href ? url.href : '';

        let highestPrice = 0;
        let startingPrice = 0;
        let finalPrice = 0;

        const imageElems = queryAll('.image-wrap img', vehicle);
        if (imageElems.length > 0) {
            const image = imageElems[0];
            if (image.getAttribute('data-src')) {
                images.push(image.getAttribute('data-src').split('?')[0]);
            } else if (image.src) {
                images.push(image.src.split('?')[0]);
            }
            vehicleData.images = images;
        }

        if (!vehicleData) {
            log('INFO', 'Vehicle data not found. Aborting inventory events.');
            return false;
        }

        if (vehicle.querySelectorAll) {
            const prices = queryAll(
                '.detailed-pricing .value, .gv-pricing .value',
                vehicle
            );

            let startingPriceFound = false;

            prices.forEach(price => {
                if (
                    isHiddenElement(price) ||
                    price.parentElement.classList.contains('stackedConditionalRebate') ||
                    price.parentElement.classList.contains('stackedConditionalFinal')
                ) {
                    return;
                }

                const formattedPrice = convertFormattedPriceToInt(price.innerText);

                if (!formattedPrice) {
                    return;
                }

                // Set highestPrice
                if (formattedPrice > highestPrice) {
                    highestPrice = formattedPrice;
                }

                // Set startingPrice
                if (!startingPriceFound) {
                    startingPrice = formattedPrice;
                    startingPriceFound = true;
                }

                // Set finalPrice
                if (
                    price.parentElement.classList.contains('stackedFinal') ||
                    price.parentElement.classList.contains('finalPrice')
                ) {
                    finalPrice = formattedPrice;
                }
            });
        }

        const dealerCodes =
            typeof vehicleData.dealercodes === 'string'
                ? convertStringToObject(vehicleData.dealercodes)
                : {};
        const optionCodes =
            typeof vehicleData.optioncodes === 'string'
                ? convertStringToArray(vehicleData.optioncodes)
                : [];

        const certified = getIsCertified(vehicleData.type, vehicle.certified);
        const inventoryType = getInventoryType(vehicleData.type, null);

        const formattedVehicle = {
            accountId: vehicleData.accountid || '',
            address: vehicleData.address || {},
            autodataCaId: vehicleData.autodatacaid || '',
            bodyStyle: vehicleData.bodystyle || '',
            certified,
            chromeId: vehicleData.chromeid || '',
            cityFuelEconomy: parseInt(vehicleData.cityfueleconomy, 10) || 0,
            classification: vehicleData.classification || '',
            dealerCodes,
            doors: vehicleData.doors || '',
            driveLine: vehicleData.driveline || '',
            engine: vehicleData.engine || '',
            engineSize: vehicleData.enginesize || '',
            deliveryDateRange: vehicleData.deliveryDateRange || '',
            exteriorColor: vehicleData.exteriorcolor || '',
            finalPrice,
            fuelType: vehicleData.fueltype || '',
            highwayFuelEconomy: parseInt(vehicleData.highwayfueleconomy, 10) || 0,
            highestPrice,
            images,
            interiorColor: vehicleData.interiorcolor || '',
            inventoryDate: vehicleData.inventorydate || '',
            inventoryType,
            link,
            make: vehicleData.make || '',
            model: vehicleData.model || '',
            modelCode: vehicleData.modelcode || '',
            odometer: parseInt(vehicleData.odometer, 10) || 0,
            oemSourcedMerchandisingStatus: vehicleData.oemSourcedMerchandisingStatus || '',
            optionCodes,
            packageCode: vehicleData.packagecode || '',
            packages: vehicleData.packages ? vehicleData.packages : '',
            startingPrice,
            status: vehicleData.status
                ? vehicleData.status.replace(/ /g, '_').toLowerCase()
                : '',
            stockNumber: vehicleData.stocknumber || '',
            transmission: vehicleData.transmission || '',
            trim: vehicleData.trim || '',
            uuid: vehicleData.uuid || '',
            vin: vehicleData.vin || '',
            year: parseInt(vehicleData.year, 10) || 0
        };

        const additionalFieldsVehicle = keys.reduce((resVehicle, key) => {
            return {
                ...resVehicle,
                [key]: vehicleData[key] || ''
            }
        }, formattedVehicle)

        return addDynamicFields(additionalFieldsVehicle, vehicleData);
    });
};

const getInventoryData = async (type, keys) => {
    window.DDC.PrivateAPI = window.DDC.PrivateAPI || {};
    if (
        !window.DDC.PrivateAPI.inventoryData ||
        window.DDC.PrivateAPI.inventoryData.length === 0
    ) {
        if (type === 'responsive') {
            window.DDC.PrivateAPI.inventoryData = await formatDataBusInventory(keys);
        } else if (type === 'legacy') {
            window.DDC.PrivateAPI.inventoryData =
                await formatLegacyDataLayerInventory(keys);
        } else if (type === 'grid') {
            window.DDC.PrivateAPI.inventoryData =
                await formatLegacyGridViewInventory(keys);
        }
    }
    return window.DDC.PrivateAPI.inventoryData;
};

export const getVehicles = async (keys = []) => {
    await hasInventoryData();

    if (hasDataBusInventory()) {
        return getInventoryData('responsive', keys);
    } else if (hasLegacyInventory()) {
        return getInventoryData('legacy', keys);
    } else if (hasLegacyGridViewInventory()) {
        return getInventoryData('grid', keys);
    }
    return [];
};

export const getVehicleData = async init => {
    const pageData = await getPageMetaData();

    if (!pageData.searchPage && !pageData.detailPage) {
        return [];
    }

    const vehicles = await getVehicles();
    trackAPIMethods(init, {
        methodType: 'getVehicleData',
        status: 'Success'
    });
    return vehicles;
};

export const getVehicleObject = async elem => {
    const pageMetaData = await getPageMetaData();
    const vehicles = await getVehicles();

    const dlVehicles = window.DDC.dataLayer.vehicles || [];

    const targetUuid =
        pageMetaData.detailPage && dlVehicles.length > 0
            ? dlVehicles[0].uuid
            : elem.closest('[data-uuid]').getAttribute('data-uuid');

    const targetVehicles = vehicles.filter(el => {
        if (el.uuid === targetUuid) {
            return el;
        }
        return false;
    });

    return targetVehicles.length > 0 ? targetVehicles[0] : null;
};

export const getAttributeForVehicles = async (init, key) => {
    const results = [];
    const vehicles = await getVehicles();

    vehicles.forEach(vehicle => {
        if (vehicle[key]) {
            results.push(vehicle[key]);
        }
    });

    if (init) {
        trackAPIMethods(init, {
            methodType: 'getAttributeForVehicles',
            status: 'Success'
        });
    }
    return results;
};