import { trackAPIMethods } from '../../../tracking'
import { getVehicles } from '../get-vehicles'
import '../../../../types/init.d'

/**
 * Gets a list of oemSourcedMerchandising attribute for each vehicle.
 * 
 * @param { Init } init 
 * @returns { Promise<{vin: string, oemSourcedMerchandisingStatus: string}[]> }
 */
export const getOemSourcedMerchandisingStatusForVehicles = async (init) => {

    // Get all vehicles
    const vehiclesData = await getVehicles('oemSourcedMerchandisingStatus')

    // format the results
    // 1. filter to found values
    // 2. format data to an object with a vin and oemSourcedMerchandisingStatus
    const results = vehiclesData.reduce((resVehicles, vehicle) => {
        if (vehicle.oemSourcedMerchandisingStatus) {
            resVehicles.push({
                vin: vehicle.vin,
                oemSourcedMerchandisingStatus: vehicle.oemSourcedMerchandisingStatus
            })
        }
        return resVehicles;
    }, [])

    // if Intigration information is available, log the action
    if (init) {
        trackAPIMethods(init, {
            methodType: 'OEM Source Merchandising Status',
            status: 'Success'
        });
    }

    // return the formatted data
    return results;
}