import { PRIMARY_BANNER } from './constants';
import { enableInlineBanner } from '../utils/helpers';

const insertions = new Set();
const listingsPlaceholderPrefix = 'listings-placeholder';
let allListingElems = null;

const getAllListingLocationElems = () => {
	if (allListingElems) {
		return allListingElems;
	}
	const selector = `[data-location*="${listingsPlaceholderPrefix}"]`;
	const elems = document.querySelectorAll(selector);
	const listingElems = new Map();
	elems.forEach(newElem => {
		const location = newElem.getAttribute('data-location');
		const existListingElems = listingElems.get(location) || [];
		listingElems.set(location, [...existListingElems, newElem]);
	});

	return listingElems;
};

const isListingLocation = location => {
	return !!location?.includes(listingsPlaceholderPrefix);
};

export const isInsertedListingLocation = location => {
	return insertions.has(location);
};

export const setInsertedListingLocation = location => {
	if (!isListingLocation(location)) {
		return;
	}
	insertions.add(location);
};

export const isPrimaryPlaceholderIntegration = integrationId => {
	return (
		integrationId === 'mywallet' || integrationId === 'toyota-smartpath-banners'
	);
};

const isInsertableToPlaceholder = (location, integration) => {
	// Placeholder 5 is always insertable
	if (location === `${listingsPlaceholderPrefix}-5`) {
		return true;
	}

	if (location === `${listingsPlaceholderPrefix}-1`) {
		// Always insertable if WIAPI is mywallet or toyota-smartpath-banners.
		// Dont care both of them are enable because my wallet should not be enabled on the same site as smart path
		if (isPrimaryPlaceholderIntegration(integration.integrationId)) {
			return true;
		}

		// Only insertable if don't have mywallet or toyota-smartpath-banners and not occupied by another WIAPI
		return !(
			window.DDC.PrivateAPI.hasPrimaryPlaceholderIntegration ||
			isInsertedListingLocation(location)
		);
	}

	if (!allListingElems) {
		allListingElems = getAllListingLocationElems();
	}

	const elems = allListingElems.get(location) || [];

	// Can not insert if not found location or location was occupied by another WIAPI
	if (elems.length === 0 || isInsertedListingLocation(location)) {
		return false;
	}

	return true;
};

const getFirstAvailableListingLocation = (currentLocation, integration) => {
	const isInsertable = isInsertableToPlaceholder(currentLocation, integration);

	// Return current location if it insertable
	if (isInsertable) {
		return currentLocation;
	}

	allListingElems = getAllListingLocationElems();
	const allAvailableListingLocations = Array.from(allListingElems.keys());

	// Sort available locations from listing-placeholder one to five
	allAvailableListingLocations.sort();

	// Find the first available location that insertable and not a current location
	// because already check that current location is not insertable and no need to check again
	return allAvailableListingLocations.find(
		availableLocation =>
			availableLocation !== currentLocation &&
			isInsertableToPlaceholder(availableLocation, integration)
	);
};

export const calculateTargetListingLocation = (location, integration) => {
	let targetLocation = location;

	// Default to listings-placeholder-1 for primary banner before calculate available location   
	if (targetLocation === PRIMARY_BANNER && enableInlineBanner()) {
		targetLocation = `${listingsPlaceholderPrefix}-1`;
	}

	if (!isListingLocation(targetLocation)) {
		return targetLocation;
	}

	// Calculate available location 
	const availableLocation = getFirstAvailableListingLocation(
		targetLocation,
		integration
	);

	setInsertedListingLocation(availableLocation);

	return availableLocation;
};
