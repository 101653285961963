import { getAttributeForVehicles } from './get-vehicles';

export const unlockPricing = async (init, ids) => {
	if (!window?.DDC?.userProfileController?.saveInstantEpriceVehicle) {
		return false;
	}

	let uuids = ids;

	if (!uuids) {
		uuids = await getAttributeForVehicles(init, 'uuid');
	}

	uuids.forEach(uuid => {
		setTimeout(() => {
			window.DDC.userProfileController.saveInstantEpriceVehicle(null, uuid);
		}, 0);
	});

	return true;
};

export const getUnlockedVehicles = async () => {
	const vehicles = window?.DDC?.userProfile?.ePriceVehicles || [];
	return [
		...new Set(
			vehicles.map(item => item?.itemUUID ?? null).filter(uuid => uuid)
		)
	];
};
