import { getPageMetaData } from './get-page-meta-data';
import { trackAPIMethods } from '../tracking';
import { featureFlags } from './feature-flags';

const observers = new Set();

export const clearObservers = () => {
	observers.forEach(observer => {
		observer.disconnect();
		observers.delete(observer);
	});
};

export const createUniqueId = () => {
	const s4 = () => {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	};
	return s4() + s4() + s4() + s4();
};

export const getHash = str => {
	if (!str) {
		return 0;
	}
	let hash = 0;
	let i;
	let chr;
	if (str.length === 0) {
		return hash;
	}

	const normalizedString = str
		.replace(/key=[0-9a-z%]+/gi, '') // Remove 'key' parameter values.
		.replace(/[^0-9a-z]/gi, ''); // Remove non-alphanumeric characters to better normalize and de-duplicate the content.

	for (i = 0; i < normalizedString.length; i++) {
		chr = normalizedString.charCodeAt(i);
		hash = (hash << 5) - hash + chr; // eslint-disable-line no-bitwise
		hash |= 0; // eslint-disable-line no-bitwise
	}
	return hash;
};

export const query = (selector, target) => {
	const scope = target || document;
	return scope.querySelector(selector);
};

export const queryAll = (selector, target) => {
	const scope = target || document;
	return Array.from(scope.querySelectorAll(selector));
};

export const activateModules = async targetEl => {
	if (typeof targetEl.querySelectorAll !== 'function') {
		return;
	}

	const pageData = await getPageMetaData();

	if (pageData.layoutType === 'desktop') {
		if (
			window.DDC?.modules?.dialog &&
			!window.DDC.modules.dialog.active
		) {
			jQuery(document).trigger('modulesRefresh', {
				modules: {
					dialog: window.DDC.modules.dialog
				}
			});
		}

		// Activate desktop popover if appropriate for inserted content.
		if (window.DDC.modules && window.DDC.modules.popover) {
			const toggleTargets = queryAll('[data-toggle="popover"]', targetEl);
			toggleTargets.forEach(target => {
				if (target.getAttribute('data-initialized') !== true) {
					window.DDC.modules.popover.load(target);
				}
			});
		}
	} else if (pageData.layoutType === 'mobile') {
		// Activate mobile slide-in on 'dialog' elements if appropriate for inserted content.
		if (typeof window?.DDC?.mobile?.slidein?.init === 'function') {
			const dialogAppend = queryAll(
				'.dialog, [data-toggle="popover"]',
				targetEl
			);
			if (dialogAppend.length > 0) {
				dialogAppend.forEach(element => {
					window.DDC.mobile.slidein.init(element);
				});
			}
		}
	}
};

export const fetchJson = async (init, url, methodName) => {
	return fetch(url, {
		headers: {
			Accept: 'application/json'
		}
	}).then(response => {
		if (!response.ok) {
			if (init.integrationId) {
				trackAPIMethods(init, {
					methodType: methodName,
					status: 'Failed'
				});
			}
			throw new Error(`Error calling the endpoint at ${url}.`);
		}
		if (init.integrationId) {
			trackAPIMethods(init, {
				methodType: methodName,
				status: 'Success'
			});
		}
		if (response.size === 0) {
			return {};
		}
		return response.json();
	});
};

export const getUrlParams = init => {
	if (window.DDC && window.DDC.getUrlParams) {
		if (init) {
			trackAPIMethods(init, {
				methodType: 'getUrlParams',
				status: 'Success'
			});
		}
		return window.DDC.getUrlParams();
	}
	if (init) {
		trackAPIMethods(init, {
			methodType: 'getUrlParams',
			status: 'Failed'
		});
	}
	return null;
};

export const getLocalizedContent = content => {
	const locale = window?.DDC?.dataLayer?.page?.attributes?.locale || 'en_US';

	let result;
	if (typeof content === 'object') {
		result = content[locale] ? content[locale] : content.en_US;
	} else if (typeof content === 'string') {
		result = content;
	}
	return result;
};

export const hasDataBusInventory = () => {
	const { InvData, dataLayer } = window?.DDC;

	if (!InvData) {
		return false;
	}

	const { srpReady } = InvData;

	const { pageInfo, inventory } = InvData?.inventory || {};

	const hasWsInvData = window?.DDC?.WS?.state['ws-inv-data'];

	return (
		InvData &&
		(!hasWsInvData || srpReady) &&
		inventory &&
		pageInfo?.totalCount >= 0 &&
		(!hasWsInvData || dataLayer?.vehicles?.length === inventory.length)
	);
};

export const hasDataBusFacets = () => {
	return window?.DDC?.InvData?.facets?.facets?.length > 0;
};

export const hasLegacyInventory = () => {
	const { InvData, dataLayer } = window.DDC;
	return (
		!InvData &&
		dataLayer?.vehicles?.length >= 0 &&
		!document.querySelector('.inventory-listing-grid .hproduct')
	);
};

export const hasLegacyGridViewInventory = () => {
	return !!query('.inventory-listing-grid .hproduct');
};

export const isVdp = () => {
	return window?.DDC?.dataLayer?.page?.pageInfo?.isVdp;
};

export const isSrp = () => {
	return window?.DDC?.dataLayer?.page?.pageInfo?.isVlp;
};

export const isNewSrp = () => {
	return isSrp() && window?.DDC?.WS?.state['ws-inv-listing'];
};

const inventoryDataStatus = {};
const inventoryDataChecker = type => {
	return new Promise(resolve => {
		if (!(isSrp() || isVdp())) {
			resolve(true);
			return;
		}

		let checker;
		const checkForData = () => {
			let result;
			if (type === 'inv') {
				result =
					hasDataBusInventory() ||
					hasLegacyInventory() ||
					hasLegacyGridViewInventory();
			} else if (type === 'facet') {
				result = hasDataBusFacets();
			}

			if (result) {
				inventoryDataStatus[type] = true;
				clearInterval(checker);
				resolve(true);
			}
		};
		checker = setInterval(checkForData, 100);
	});
};

export const hasInventoryData = () => {
	return inventoryDataChecker('inv');
};

export const hasFacetData = () => {
	return inventoryDataChecker('facet');
};

export const enableInlineBanner = () =>
	isNewSrp() && featureFlags.inlinePrimaryBanner;
