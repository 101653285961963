import { log, traceMode } from './log';

export class Location {
	constructor(name) {
		this.name = name;
		this.getElements = this.getElements.bind(this);
		this.getMeta = this.getMeta.bind(this);
		this.isReady = this.isReady.bind(this);
	}

	isReady() {
		return true;
	}

	async getElements(vehicle) {
		const selector = `[data-location~="${this.name}"]`;
		const itemSelector = vehicle?.uuid
			? document.querySelector(`[data-uuid="${vehicle.uuid}"]`)
			: document;

		if (!itemSelector) {
			return [];
		}

		const elems = itemSelector.querySelectorAll(selector);

		if (elems.length === 0 && traceMode) {
			log(
				'WARNING',
				`The '${this.name}' location was targeted on this page but does not exist.`
			);
		}

		return elems;
	}

	async getConfig() {
		return {
			elementType: 'div'
		};
	}

	prepareLocation() {
		return true;
	}

	getMeta(_elem) {
		return null;
	}
}
