// eslint-disable-next-line max-classes-per-file
import { hideDefaultPayments, showDefaultPayments } from './payments';
import {
	getAttributeForVehicles,
	getOemSourcedMerchandisingStatusForVehicles,
	getOemSourcedMerchandisingStatusByVin,
	getVehicleData
} from './get-vehicles';
import { getConfig, getSitemap, getSiteProperties } from './load-configs';
import { getDealerData } from './get-dealership-data';
import { getItemList } from './item-lists';
import { getJwtForSite, getJwtForVehicles } from './get-jwts';
import { getPageData } from './get-page-meta-data';
import { getSettings } from './settings';
import { getUnlockedVehicles, unlockPricing } from './unlock-pricing';
import { getUrlParams } from './helpers';
import { getPixallVisitorId } from '../tracking';

export class Utils {
	constructor(init) {
		this.init = init;
	}

	getIntegrationId() {
		return this.init.integrationId;
	}

	getIntegrationType() {
		return this.init.integrationType;
	}
}

export class PrivateUtils extends Utils {
	constructor(init) {
		super();
		this.init = init;
	}
}

const classesToDecorate = [
	{
		className: Utils,
		methods: {
			getAttributeForVehicles,
			getConfig,
			getDealerData,
			getJwtForSite,
			getJwtForVehicles,
			getOemSourcedMerchandisingStatusForVehicles,
			getOemSourcedMerchandisingStatusByVin,
			getPageData,
			getPixallVisitorId,
			getSitemap,
			getSiteProperties,
			getUnlockedVehicles,
			getUrlParams,
			getVehicleData,
			unlockPricing
		}
	},
	{
		className: PrivateUtils,
		methods: {
			getItemList,
			getSettings,
			hideDefaultPayments,
			showDefaultPayments
		}
	}
];

classesToDecorate.forEach(decorateTarget => {
	const item = decorateTarget;
	Object.entries(item.methods).forEach(([name, method]) => {
		item.className.prototype[name] = function utilMethod(...arg) {
			const init = {
				integrationId: this.getIntegrationId(),
				integrationType: this.getIntegrationType()
			};

			// getUrlParams is a synchronous function.
			if (name === 'getUrlParams') {
				return method.call(null, init, ...arg);
			}

			return new Promise((resolve, reject) => {
				try {
					resolve(method.call(null, init, ...arg));
				} catch (err) {
					reject(err);
				}
			});
		};
	});
});
